import { Alert, Card, CardActions, CardContent, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRouter } from "next/router";
import { ContainedButton } from "@/lib/components/contained-button";

const CenteredContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
});

const ColoredBackground = styled("div")({
  backgroundColor: "#00b55d",
  height: "100%",
  width: "100%",
});

export default function Custom404() {
  const router = useRouter();

  function redirectToHome() {
    router.push("/");
  }

  return (
    <ColoredBackground>
      <CenteredContainer>
        <Container maxWidth="sm" fixed={true} disableGutters={true}>
          <Card raised={true}>
            <CardContent>
              <Alert severity="error">
                <Typography>Uh Oh! The requested page does not exist.</Typography>
              </Alert>
            </CardContent>
            <CardActions>
              <ContainedButton onClick={redirectToHome}>Home Page</ContainedButton>
            </CardActions>
          </Card>
        </Container>
      </CenteredContainer>
    </ColoredBackground>
  );
}
